import {Component, Inject, OnInit, OnDestroy, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {InitializeService} from '../../../../services/initialize.service';
import {isPlatformBrowser} from '@angular/common';
import {StorageService} from '../../../../services/storage.service';
import {RemoteServer} from '../../../../config/config.remoteServer';
import {CustomerService} from '../../../../services/customer.service';


@Component({
  selector: 'app-forget-password-form',
  templateUrl: './forget-password-form.component.html',
  styleUrls: ['./forget-password-form.component.css']
})
export class ForgetPasswordFormComponent implements OnInit, OnDestroy{
  result: any;
  mobileActive:boolean = false;//聚焦手机号框
  passwordActive:boolean = false;//聚焦密码框
  rePasswordActive:boolean = false;//聚焦重置密码框
  phoneCaptchaActive:boolean = false;//聚焦验证码框

  mobileVal!: string;//手机号（注册）
  passwordVal!: string;//密码（注册）
  rePasswordVal!: string;//确认密码（注册）

  usernameError!: string;//用户名验证错误提示
  passwordError!: string;//密码验证错误提示
  rePasswordError!: string;//重复密码错误提示（注册）
  phoneCaptchaError:string = '验证码错误';//手机验证错误提示（注册）
  mobileValid:boolean = false;//手机号验证
  passwordValid:boolean = false;//密码验证(注册)
  rePasswordValid:boolean = false;//确认密码验证(注册)
  phoneCaptchaValid:boolean = false;//验证码验证(注册) ture 显示错误
  phoneCaptchaDisabled =false;//发送验证码禁用
  phoneCaptchaValue!: string;
  sendPhoneLabel:string ='发送验证码';
  setPasswordText!: string;
  hiddenImageValidBox:boolean=true;//图片验证框显示(注册)
  imageValidId!: string; //图片验证码id
  imageValidSrc!: string; //图片验证码地址
  imageValidValue!: string;//图像验证码输入值
  imageValidError:boolean=false;//图形验证码错误
  imageValidErrorLabel!: string;//图形验证码提示
  public timer : any;
  constructor(public init: InitializeService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private HOST:RemoteServer,
              private customer:CustomerService,
              private localStorage: StorageService,
              private router: Router) {
  }

  ngOnInit() {
    if(this.localStorage.getItem('noSetPassword') === 'yes'){
      this.setPasswordText = '设置密码';
    }else{
      this.setPasswordText = '找回密码';
    }
    this.createImage();
  }
  mobileActiveCheck(){
    this.mobileValid = false;
    this.mobileActive = true;
  }
  passwordActiveCheck(){
    this.passwordValid = false;
    this.passwordActive = true;
  }
  rePasswordActiveCheck(){
    this.rePasswordValid = false;
    this.rePasswordActive=true;
  }
  phoneCaptchaActiveCheck(){
    this.phoneCaptchaValid = false;
    this.phoneCaptchaActive=true;
  }
  //手机号校验
  blurMobileCheck() {
    if(this.mobileVal) {
      const myreg = /^((1[3|4|5|6|7|8|9])\d{9})|(([6|9])\d{7})$/;
      let valid = myreg.test(this.mobileVal);
      this.mobileValid = valid ? false : true;
      this.usernameError =valid?'':'手机号格式不正确';
    }else{
      this.mobileValid=true;
      this.usernameError ='请输入手机号';
    }
    this.mobileActive=false;
    if(this.mobileValid){
      if (isPlatformBrowser(this.platformId)) {
        const that = this;
        this.timer = setTimeout(function () {
          that.mobileValid = false;
        }, 3000);
      }
      return false;
    }
    return true;
  }
  //密码校验
  blurPasswordCheck() {
    if(this.passwordVal) {
      const myreg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%\^&*?]{8,16}$/;
      let valid = myreg.test(this.passwordVal);
      this.passwordValid = valid ? false : true;
      this.passwordError =valid?'':'密码必须为8～16个英文字母加数字或符号的组合';
    }else{
      this.passwordValid = true;
      this.passwordError ='请输入密码';
    }
    this.passwordActive=false;
    if(this.passwordValid){
      const that =this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(function () {
          that.passwordValid = false;
        }, 3000);
        return false;
      }
    }
    return true;
  }
  reBlurPasswordCheck(){
    if(this.rePasswordVal&&this.rePasswordVal!=this.passwordVal) {
      this.rePasswordError ='两次输入的密码不一致';
      this.rePasswordValid = true;
    }else if(!this.rePasswordVal){
      this.rePasswordValid = true;
      this.rePasswordError ='请确认您的密码';
    }else{
      this.rePasswordValid = false;
    }
    this.rePasswordActive=false;
    if(this.rePasswordValid){

      let that =this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer=setTimeout(function () {
          that.rePasswordValid = false;
        }, 3000);
        return false;
      }
    }
    return true;
  }
  blurPhoneCaptchaCheck(){
    this.phoneCaptchaActive=false;
  }

  checkVerificationCode() {
    if (!this.phoneCaptchaValue) {
      this.phoneCaptchaValid=true;
      this.phoneCaptchaError = '验证码必填';
      const that =this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(function () {
          that.phoneCaptchaValid = false;
        }, 3000);
      }
      return false;
    }else{
      this.phoneCaptchaValid=false;
    }

    let codeResult: any = {result: '', msg: '',data:''};
    const params = {mobile:this.mobileVal,verifyCode:this.phoneCaptchaValue};
    this.customer.validateVerifyCode(JSON.stringify(params)).then( val => {
      codeResult = val;
      if(codeResult.code === 1000){
        this.phoneCaptchaValid = false;
		return;
      }else{
        this.phoneCaptchaValid = true;
        this.phoneCaptchaError = '验证码错误';
        const that = this;
        if (isPlatformBrowser(this.platformId)) {
          this.timer = setTimeout(() => {
            that.phoneCaptchaValid = false;
          }, 3000);
        }
        this.createImage();
        return false;
      }
    }).catch(error => error);
    return true;
  }
  /*发送手机验证码*/
  sendPhoneVerification() {
    if (!this.blurMobileCheck()) {
      return false;
    }
    this.phoneCaptchaDisabled = true;
    const that = this;
    let sendResult: any = {code: '', msg: '',result:''};

    let smsSign = this.init.aliyunSmsSign;
    let smsTemplateId = this.init.forgotPasswordSmsTplId;
    let platform = "aliyun";
    let tempMobile = this.mobileVal;
    const hkmyreg = /^(([6|9])\d{7})$/;
    if (hkmyreg.test(this.mobileVal)) {
      smsSign = this.init.aliyunSmsHkSign;
      smsTemplateId = this.init.forgotPasswordSmsHkTplId;
      tempMobile = "852"+tempMobile;
    }

    const params = {merchantId:this.init.merchantId,mobile:tempMobile,signName:smsSign,templateId:smsTemplateId,templateParams:{},platform:platform};
    this.customer.sendVerifyCode(JSON.stringify(params)).then( val => {
      sendResult = val;
      if(sendResult.code === 1000){
        this.localStorage.setItem('is_send_register_code', new Date().valueOf());
        let int = 59;
        const timerEffect =  setInterval(function () {
          that.sendPhoneLabel = '重新发送(' + int-- + ')';
          if (int <= 1) {
            that.sendPhoneLabel = '发送验证码';
            that.phoneCaptchaDisabled = false;
            clearInterval(timerEffect);
          }
        }, 1000);
      }else{
        this.phoneCaptchaValid = true;
        this.phoneCaptchaDisabled = false;
        if(sendResult.msg === '触发小时级流控Permits:5'){
          this.phoneCaptchaError = '验证码发送过于频繁，请稍后再试';
        }else{
          this.phoneCaptchaError = sendResult.msg;
        }
        if (isPlatformBrowser(this.platformId)) {
          this.timer = setTimeout(() => {
            that.phoneCaptchaValid = false;
          }, 3000);
        }
      }
    }).catch(error => error);
	return;
  }
  findPassword() {
    if (this.blurMobileCheck() && this.checkVerificationCode() && this.blurPasswordCheck() && this.reBlurPasswordCheck()) {
      let create: any = {code: '', msg: '',data:''};
      const mobile = this.mobileVal;
      const verification_code = this.phoneCaptchaValue;
      const password = this.passwordVal;
      const confirmation_password = this.rePasswordVal;
      const params = {confirmPassword:confirmation_password,merchantId:this.init.merchantId,mobile:mobile,newPassword:password,verifyCode:verification_code,smsPlatform:this.init.smsPlatform};
      this.customer.forgotPassword(JSON.stringify(params)).then(val => {
            create = val;
            if(create.code === 1000){
              //this.findPasswordErrorLabel = "密码找回成功";
              this.localStorage.removeItem('noSetPassword');
              const that = this;
              if (isPlatformBrowser(this.platformId)) {
                this.timer = setTimeout(function () {
                  that.router.navigate(['']);
                }, 3000);
              }
			  return;
            }else{
              this.rePasswordValid = true;
              this.rePasswordError = create.msg;
              const there = this;
              this.timer = setTimeout(function () {
                there.rePasswordValid = false;
              }, 3000);
              return false;
            }
      }).catch(error => error);
    }
  }

  openImageValid() {
    this.phoneCaptchaValue = '';
    this.imageValidValue = '';
    if (!this.blurMobileCheck()) {
      return false;
    }
    this.hiddenImageValidBox = false;
	return;
  }

  createImage() {
    let host = this.HOST.BASIC_HOST;
    let captcha_key_result: any = {code: '', data: {'captchaKey': ''}, msg: ''};
    this.customer.imageCaptchaKey().then( val => {
      captcha_key_result = val;
      if (captcha_key_result.code === 1000) {
        this.imageValidId = captcha_key_result.data.captchaKey;
        this.imageValidSrc = host+this.customer.imageCaptcha(captcha_key_result.data.captchaKey);
      }
    }).catch(error => error);
  }

  imageValueCheck() {
    if (this.imageValidValue !== undefined && this.imageValidValue !== '') {

      let result: any = {code: '', msg: '',data:{}};
      const params = {'captchaKey':this.imageValidId,'captchaValue':this.imageValidValue};
      this.customer.checkImageCaptcha(JSON.stringify(params)).then( val => {
        result = val;
        if(result.code === 1000){
          this.closeCodeBox();
          this.sendPhoneVerification();
        }else{
          this.imageValidError = true;
          this.imageValidErrorLabel = '验证码错误';
          const _that = this;
          if (isPlatformBrowser(this.platformId)) {
            this.timer = setTimeout(function () {
              _that.imageValidError = false;
              _that.imageValidErrorLabel = '验证码错误';
            }, 3000);
          }
          return false;
        }
		return;
      });
    } else {
      this.imageValidError = true;
      this.imageValidErrorLabel = '请输入验证码';
      //if (isPlatformBrowser(this.platformId)) {
      //  this.timer = setTimeout(() => {
      //    these.imageValidError = false;
      //  }, 3000);
      //}
    }
  }
  closeCodeBox() {
    //this.createImage();
    this.imageValidError = false;
    this.hiddenImageValidBox = true;
  }
  checkInfoConfirm(){
    if((this.mobileVal && this.mobileVal.length > 0 && !this.mobileValid) &&
      (this.phoneCaptchaValue && this.phoneCaptchaValue.length > 0 && !this.phoneCaptchaValid) &&
      (this.passwordVal && this.passwordVal.length > 0 && !this.passwordValid) &&
      (this.rePasswordVal && this.rePasswordVal.length > 0 && !this.rePasswordValid)
    ){
      return true;
    }
    return false;
  }
  ngOnDestroy() {
    this.timer = null;
  }
}

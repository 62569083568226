import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {InitializeService} from '../../../../services/initialize.service';
import {HttpParams} from '@angular/common/http';
import {RequestService} from '../../../../services/request.service';
import {Router} from '@angular/router';
import {ResetpasswordService} from '../../../../services/resetpassword.service';
import {StorageService} from '../../../../services/storage.service';
import {CallComponentService} from '../../../../services/callcomponent.service';
import {isPlatformBrowser} from "@angular/common";

const apiConfig = require('../../../../config/config.api.json');

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.css']
})
export class ResetPasswordFormComponent implements OnInit {

  currentPasswordVal!: string;//原密码
  currentPasswordActive:boolean=false;//聚焦原密码框
  currentPasswordError:boolean=false;//原密码错误
  currentPasswordErrorLabel!: string;//原密码错误提示
  passwordVal!: string;//密码
  rePasswordVal!: string;//密码（注册）
  passwordActive:boolean=false;//聚焦密码框
  rePasswordActive:boolean=false;//聚焦确认密码框
  passwordError:boolean=false;//密码校验错误
  passwordErrorLabel!: string;//密码错误提示
  rePasswordError:boolean=false;//确认密码校验错误
  rePasswordErrorLabel!: string;//确认密码错误提示
  resetPassword:boolean=false;//重置密码
  resetPasswordMessage:string='重置密码失败';


  constructor(
              public init: InitializeService,
              public request: RequestService,
              private router: Router,
              private reset: ResetpasswordService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private localStorage: StorageService) {
  }

  ngOnInit() {
  }
  activePassword(){
    this.passwordActive=true;
    this.passwordError=false;
  }
  activeRePassword(){
    this.rePasswordActive=true;
    this.rePasswordError=false;
  }
  activeCurrentPassword(){
    this.currentPasswordActive=true;
    this.currentPasswordError=false;
  }
  currentPasswordValid(){
    if(this.currentPasswordVal) {
      const myreg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%\^&*?]{6,16}$/;
      let valid = myreg.test(this.currentPasswordVal);
      this.currentPasswordError = (valid ? null : true) as boolean;
      this.currentPasswordErrorLabel = valid?'':'原密码为6～16个英文字母加数字或符号的组合';
    }else{
      this.currentPasswordError = true;
      this.currentPasswordErrorLabel ='请输入原密码';
    }
    this.currentPasswordActive=false;
    if(this.currentPasswordError){
      const that =this;
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(function () {
          that.currentPasswordError = false;
        }, 3000);
      }
      return false;
    }
    return true;
  }
  passwordValid(){
    if(this.passwordVal) {
      const myreg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%\^&*?]{8,16}$/;
      let valid = myreg.test(this.passwordVal);
      this.passwordError = valid ? false : true;
      this.passwordErrorLabel =valid?'':'密码必须为8～16个英文字母加数字或符号的组合';
    }else{
      this.passwordError = true;
      this.passwordErrorLabel ='请输入密码';
    }
    this.passwordActive=false;
    if(this.passwordError){
      const that =this;
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(function () {
          that.passwordError = false;
        }, 3000);
      }
      return false;
    }
    return true;
  }
  rePasswordValid(){
    if(this.rePasswordVal&&this.rePasswordVal!=this.passwordVal) {
      this.rePasswordError = true;
      this.rePasswordErrorLabel ='两次输入的密码不一致';
    }else if(!this.rePasswordVal){
      this.rePasswordError = true;
      this.rePasswordErrorLabel ='请确认您的密码';
    }else{
      this.rePasswordError = false;
    }
    this.rePasswordActive=false;
    if(this.rePasswordError){
      const that =this;
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(function () {
          that.rePasswordError = false;
        }, 3000);
      }
      return false;
    }
    return true;
  }
  onSubmit() {
    if (this.passwordValid() || this.rePasswordValid()) {
      let result: any = {code: '', message: ''};
      const params = new HttpParams()
        .set('current_password', this.currentPasswordVal)
        .set('new_password', this.passwordVal)
        .set('confirmation', this.rePasswordVal);
      this.request.put(apiConfig.customerApi+'?action=update_pwd', params)
        .subscribe(
          val => {
            result = val;
            if (result.code === 200) {
              this.resetPassword=true;
              this.resetPasswordMessage ='密码修改成功';
              const _that = this;
              if (isPlatformBrowser(this.platformId)) {
                setTimeout(function () {
                  _that.router.navigate(['my-account/index']);
                }, 3000);
              }
            } else {
              this.resetPassword=true;
              this.resetPasswordMessage=result.msg;
              const that =this;
              if (isPlatformBrowser(this.platformId)) {
                setTimeout(function () {
                  that.resetPassword = false;
                }, 3000);
              }
            }
          },
        );
    }

  }
}

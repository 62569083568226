<form id="updatePasswordForm" class="o-form font_s_regular edit-container">
  <h3 class="font_s_regular mb-30 md-hidden">修改密码</h3>
  <div class="input-wrapper">
    <div data-component="MInput" class="m-input entry-item border-b">
      <ng-container *ngIf="oldPwd; else uBlock;">
        <label class="a-label js-a-label mb-5 tips">请输入您的密码</label>
      </ng-container>
      <ng-template #uBlock>
        <label class="a-label js-a-label mb-5" *ngIf="oldPasswordVal && oldPasswordVal.length > 0">旧密码</label>
      </ng-template>
      <input class="p-0 border-none" type="password" id="currentPassword" name="currentPassword" required pattern="^[^ ]{8,16}$" placeholder="旧密码" [(ngModel)]="oldPasswordVal" (keyup)="inpOldPwd()"  (blur)="inpOldPwd()">
    </div>
    <div data-component="MInput" class="m-input entry-item bt-none">
      <ng-container *ngIf="passWord; else nBlock;">
        <label class="a-label js-a-label mb-5 tips">{{newPassWord}}</label>
      </ng-container>
      <ng-template #nBlock>
        <label class="a-label js-a-label mb-5" *ngIf="newPasswordVal && newPasswordVal.length > 0">新密码</label>
      </ng-template>
      <input class="p-0 border-none" type="password" id="newPassword" name="newPassword" required pattern="^[^ ]{8,16}$" placeholder="新密码" [(ngModel)]="newPasswordVal" (blur)="inpPassword()">
    </div>
    <div data-component="MInput" class="m-input entry-item bt-none">
      <ng-container *ngIf="rePassWord; else rBlock;">
        <label class="a-label js-a-label mb-5 tips">{{affirmPassWord}}</label>
      </ng-container>
      <ng-template #rBlock>
        <label class="a-label js-a-label mb-5" *ngIf="rePasswordVal && rePasswordVal.length > 0">确认新密码</label>
      </ng-template>
      <input class="p-0 border-none" type="password" id="checkNewPassword" name="checkNewPassword" required placeholder="确认新密码" [(ngModel)]="rePasswordVal" (blur)="inpConfirm()">
    </div>
   <!-- <div id="errorBox" class="m-error js-m-error is-hidden">
      <label id="errorLabel" class="a-label js-a-label has-error">
      </label>
    </div>
    <div style="margin-top: 300px;" data-component="MInput" class="m-input" [class.has-error]="oldPwd" id="oldPassword">
      <label class="a-label js-a-label">旧密码</label>
      <input class="a-input js-a-input" type="password" id="currentPassword1" name="currentPassword" required pattern="^[^ ]{6,25}$" placeholder="Old password" [(ngModel)]="oldPasswordVal" (keyup)="inpOldPwd()"  (blur)="inpOldPwd()">
      <label class="a-label js-a-label">请输入您的密码</label>
    </div>
    <div data-component="MInput" class="m-input" [class.has-error]="passWord" id="password">
      <label class="a-label js-a-label">新密码</label>
      <input class="a-input js-a-input" type="password" id="newPassword1" name="newPassword" required pattern="^[^ ]{6,25}$" placeholder="New password" [(ngModel)]="newPasswordVal" (blur)="inpPassword()">
      <label class="a-label js-a-label">{{newPassWord}}</label>
    </div>
    <div data-component="MInput" class="m-input" [class.has-error]="rePassWord" id="no-description" data-validation-match-field="password">
      <label class="a-label js-a-label">确认新密码</label>
      <input class="a-input js-a-input" type="password" id="checkNewPassword1" name="checkNewPassword" required placeholder="确认新密码" [(ngModel)]="rePasswordVal" (blur)="inpConfirm()">
      <label class="a-label js-a-label">{{affirmPassWord}}</label>
    </div>-->
  </div>
  <div class="button-container mt-40">
    <button type="submit" (click)="onSubmit()" class="a-button is-primary btn-save-password h-50">
      <span>保存</span>
    </button>

    <!--<button type="submit" (click)="onSubmit()" class="a-button is-primary btn-save-password h-50">
      <span>取消</span>
    </button>
    <a class="a-button btn-cancel" routerLink="/my-account/profile">取消</a>-->
  </div>
  <div>
    <input type="hidden" name="CSRFToken" tabindex="104">
  </div>
</form>
<!-- 弹框：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': show_modal}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="showError" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{message}}</p>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>

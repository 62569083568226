<div class="sizebox-container" style="transform: translateY(0%) translateZ(0px);">
	<div aria-hidden="true" class="pageTopLayer-bg" (click)="closeSizeLayer()"></div>
		<div class="iconBox" style="position: fixed;">
			<div class="iconBuoy"></div>
		</div>
	<div class="m-cart-addition zindex-10" id="size-item">
		<div class="icon_close" (click)="closeSizeLayer()">
			<svg class="inline h-4 w-4 fill-current">
				<title>关闭</title>
				<use href="/assets/iconSprite.svg#nav-close-desktop"></use>
			</svg>
		</div>
		<div class="subBox" *ngIf="showItemInfo">
			<div class="slide">
				<div class="arrow_left" [ngStyle]="{'display':currentSlide == 1 ?'none':'flex'}">
					<img src="https://100000005-1252208446.file.myqcloud.com/images/newMP/right_a2.png" />
				</div>
			
				<div class="arrow_right" [ngStyle]="{'display':currentSlide == slideTotal ?'none':'flex'}">
					<img src="https://100000005-1252208446.file.myqcloud.com/images/newMP/right_a2.png" />
				</div>
				<div class="collection">
					<ng-container *ngIf="favoriteItemIds.hasOwnProperty(productModel.itemId+'_'+productModel.valueId); else notin">
						<svg class="inline h-16 w-16 transition fill-current" (click)="removeFavorites(productModel.itemId,productModel.valueId)">
							<title>取消收藏</title>
							<use href="/assets/iconSprite.svg#wishlist-active"></use>
						</svg>
					</ng-container>
					<ng-template #notin>
						<svg class="inline h-16 w-16 transition fill-current" (click)="appendFavorites(frontCode,productModel.itemId,productModel.valueId)">
							<title>收藏</title>
							<use href="/assets/iconSprite.svg#nav-heart-desktop"></use>
						</svg>
					</ng-template>
				</div>
				<div class="o-slider swiper-container" [swiper]="mySwiperConfig" (indexChange)="onIndexChange($event)">
					<div class="imgList slider swiper-wrapper">
						<ng-container *ngFor="let img of ImageArray;index as i;">
							<div [ngStyle]="{'display':img.type == 'video' && !showVideo || includeVideo && i == 1?'none':'block'}" class="swiper-slide" [ngClass]="{'swiper-slide-padding': currentSlide > 1}">
								<div class="imgItem swiper-zoom-container">
									<div *ngIf="img.type == 'video';else pcDefault">
										<app-vjs-player [miniVideo]="miniVideo" [options]="{ autoplay: false, controls: true,sources: [{ src:img.url, type: 'video/mp4'}]}" [poster]="imageList[1].url"></app-vjs-player>
									</div>
									<ng-template #pcDefault>
										<img *ngIf="img.type == 'image'" class="default-image object-cover" src="{{img.url}}"
											alt="图片">
									</ng-template>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>


		<div class="goodsInfo">
			<div class="gTitle" *ngIf="showItemInfo">
				<h6>{{productModel.itemName}} </h6>
				<span class="price">
					<ng-container *ngIf="!!salePrice;else originalPrice">
						<label class="a-label js-a-label" [ngClass]="{'is-reduced': productModel.cloudPrice != salePrice}">{{salePrice |currency:currency}}</label>
						<label class="a-label js-a-label is-deprecated" *ngIf="productModel.cloudPrice != salePrice">{{productModel.cloudPrice | currency:currency}}</label>
					</ng-container>
					<ng-template #originalPrice>
						<label class="a-label js-a-label" [ngClass]="{'is-reduced': productModel.cloudPrice != productModel.cloudSalePrice}">{{productModel.cloudPrice |currency:currency}}</label>
						<label class="a-label js-a-label is-deprecated" *ngIf="productModel.cloudPrice != productModel.cloudSalePrice">{{productModel.cloudSalePrice | currency:currency}}</label>
					</ng-template>
				</span>
			</div>
			<div class="sizeBox">
				<div class="sizeTitle">尺码选择
					<ng-container>
						<span *ngIf="isSoldOutSoon" class="low-stock" id="isSoldOutSoon">- 库存少于3件</span>
						<span *ngIf="sizeSoldOut" class="low-stock" id="sizeSoldOut">- 已售罄</span>
					</ng-container>
				</div>
				<div class="sizeList">
					<ng-container *ngFor="let sizeBlock of list">
						<ng-container *ngIf="!hideSizeChart || (hideSizeChart && sizeBlock.qty > 0)">
							<div class="sizeItem" [ngClass]="{'nods': sizeBlock.qty <= 0}"
								(mouseover)="glideSize(sizeBlock)"
								(mouseout)="isSoldOutSoon = false;sizeSoldOut = false;" (click)="onSelect(sizeBlock)">
 
								<ng-container *ngIf="productService.splitSize(sizeBlock.valueName).length == 3;else normalSize">
									{{productService.splitSize(sizeBlock.valueName)[0]+'('+productService.splitSize(sizeBlock.valueName)[1]}}
									{{'('+productService.splitSize(sizeBlock.valueName)[2]}}
								</ng-container>
								<ng-template #normalSize>
									<ng-container *ngIf="productService.splitSize(sizeBlock.valueName).length > 1;else oneSizeTpl">
										{{productService.splitSize(sizeBlock.valueName)[0]}}
										{{'('+productService.splitSize(sizeBlock.valueName)[1]}}
									</ng-container>
									<ng-template #oneSizeTpl>
										{{productService.splitSize(sizeBlock.valueName)}}
									</ng-template>
								</ng-template>

								 
								<div class="cartIcon" *ngIf="(tabId =='add-to-buy' || showItemInfo) && sizeBlock.qty > 0"><img style="width:16px;"
										src="https://100000005-1252208446.file.myqcloud.com/images/newMP/bag_b_v2.png" />
								</div>
								<div *ngIf="sizeBlock.qty > 0 && sizeBlock.qty <= 3" class="tip1"></div>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>

			<div class="sizeGuide" *ngIf="(!!sizeTableImageUrl || hasSizeGuide)" (click)="clickSizeGuide()">
				尺码指南
				<svg id="plus-svg" class="plus-minus-svg" [ngClass]="{'plus-svg-quick': showItemInfo}">
					<use href="/assets/iconSprite.svg#plus-small"></use>
				</svg>
				<svg id="minus-svg" class="plus-minus-svg hidden" [ngClass]="{'minus-svg-quick': showItemInfo}">
					<use href="/assets/iconSprite.svg#minus-small"></use>
				</svg>
			</div>
		</div>
		
		
		<ng-container *ngIf="showItemInfo">
			<div class="item-details" (click)="itemDetails()">
				<span>
					<h2 class="font_small_xs_regular" data-testid="heading">查看完整页面</h2>
				</span>
				<span>
					<svg class="right-svg"><use href="/assets/iconSprite.svg#chevron-right"></use></svg>
				</span>
			</div>
		</ng-container>
		
	</div>

	<!-- 弹层：尺码指南 -->
	<div class="size-guide" [ngClass]="{'size-guide-mini-quick': showItemInfo}" id="size-guide-mini" style="display: none;">
		<div class="icon_close" (click)="clickSizeGuide()">
			<svg class="inline h-4 w-4 fill-current"><title>关闭</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
		</div>
		<div class="bTitle">尺码指南</div>
	  <div class="drop-down">
			<div class="dnItem">
				<div class="dnTitle">
					商品尺寸表
				</div>
				<div class="sizeTable">
					<!-- 尺码图片 -->
					<div *ngIf="hasSizeGuide;else oldSizeTableImg">
						<img *ngIf="!!measurePic" alt="COS" class="sizeTableImg" src="{{measurePic}}">
						<div class="dnItem" *ngIf="!!howToMeasurePic">
							<div class="dnTitle">
								如何测量
								<div class="rightIcon">
									<div *ngIf="showMeasureMethod;else hideMeasureMethod" (click)="operateMeasureMethod()">
										<svg class="plus-minus-svg"><title></title><use href="/assets/iconSprite.svg#minus-small"></use></svg>
									</div>
									<ng-template #hideMeasureMethod>
										<div (click)="operateMeasureMethod()">
											<svg class="plus-minus-svg"><title></title><use href="/assets/iconSprite.svg#plus-small"></use></svg>
										</div>
									</ng-template>
								</div>
							</div>
							<div class="sizeTable">
								<img *ngIf="showMeasureMethod" alt="COS" class="sizeTableImg" src="{{howToMeasurePic}}">
								<img *ngIf="!!sizeChartPic" alt="COS" class="sizeTableImg" src="{{sizeChartPic}}">
								<img *ngIf="!!tryonPic" alt="COS" class="sizeTableImg" src="{{tryonPic}}">
							</div>
						</div>
					</div>
					<ng-template #oldSizeTableImg>
						<img alt="COS" id="size-guide-template-image" class="sizeTableImg" src="{{finalSizeImageUrl}}">
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="o_login_reg is-active">
  <ul data-component="MTablist" class="m-tablist tabs-1-2 border-none" data-tablist-for="sign-in-registration-tabs" id="sign-in-registration-tabs">
    <li *ngIf="showBindMobile == true" class="u-no-select" [ngStyle]="{'display':showBindMobile?'block':'none'}" [ngClass]="{'is-active':showBindMobile==true}" style="text-align: center;width:100%;">
      <a href="javascript:void(0)" target="_self" class="a-link">绑定手机</a>
    </li>
    <!-- 登录弹出框需加 is-active类 -->
    <li *ngIf="!showBindMobile" data-toggle="#sign-in-form" class="u-no-select lg-text-right pr-20" [ngClass]="{'is-active':showLoginBox==true}">
      <a href="javascript:void(0)" (click)="showLogin()" target="_self" class="a-link" id="login">登录</a>
    </li>
    <li *ngIf="!showBindMobile" data-toggle="#registration-form" class="u-no-select text-left pl-20" [ngClass]="{'is-active':showRegisterBox==true}">
      <a href="javascript:void(0)" (click)="showRegister()" target="_self" class="a-link" id="signed">注册</a>
    </li>
  </ul>

  <div data-content-for="sign-in-registration-tabs" class="tablist-content">
    <!-- 登录弹出框需加 is-active类 -->
    <div id="sign-in-form" class="content" [ngClass]="{'is-active':showLoginBox==true}">
      <div class="o-sign-in in-lightbox sign-box">
        <form data-component="OForm" class="o-form"  (submit)="login()">
          <div class="m-error js-m-error is-hidden">
            <label class="a-label js-a-label has-error"></label>
          </div>
          <!-- 错误提示，加is-filled has-error类 -->
          <div data-component="MInput" class="m-input entry-item">
            <ng-container *ngIf="usernameValid; else otherBlock;">
              <label for="l_username" class="a-label js-a-label mb-5 tips">{{usernameError}}</label>
            </ng-container>
            <ng-template #otherBlock>
              <label for="l_username" class="a-label js-a-label mb-5" *ngIf="usernameVal && usernameVal.length > 0">手机号</label>
            </ng-template>
            <input id="l_username" placeholder="手机号" autocomplete="off" maxlength="11"  class="p-0 border-none" type="tel" name="l_username" [(ngModel)]='usernameVal' required (blur)="blurMobileCheck()"   (focus)="usernameActiveCheck()" title="手机号">
            <!-- <label *ngIf="usernameValid" class="a-label js-a-label" data-placeholder="">{{usernameError}}</label>-->
          </div>
          <!-- 点击后加is-active类 -->
          <!--[ngClass]="{'has-error':passwordValid===true && !notSetPassword,'is-active':passwordActive===true }"-->
          <div data-component="MInput" class="m-input no-description entry-item bt-none mb-15">
            <ng-container *ngIf="passwordValid; else otherBlock1;">
              <label for="user_password" class="a-label js-a-label mb-5 tips">{{passwordError}}</label>
            </ng-container>
            <ng-template #otherBlock1>
              <label for="user_password" class="a-label js-a-label mb-5" *ngIf="passwordVal && passwordVal.length > 0">密码</label>
            </ng-template>
            <div style="display: flex; width: 100%; height: 18px;">
              <input id="user_password" placeholder="密码" autocomplete="off" name="l_password" class="p-0 border-none" [(ngModel)]='passwordVal' [type]="isText? 'text' : 'password'" required (focus)="passwordActiveCheck()" title="密码">
              <em class="iconfont eyes" [ngClass]="{'icon-yanjing-bi': !eyesOpen}" [class.icon-yanjing-zheng]="eyesOpen" (click)="passWordShow()"></em>
            </div>
            <!-- <label *ngIf="passwordValid===true && !notSetPassword" class="a-label js-a-label" data-placeholder="">{{passwordError}}</label>-->
          </div>
          <label *ngIf="loginError" class="a-label js-a-label" style="color: #D44511; font-size: 12px;">用户名密码错误</label>
          <label *ngIf="notSetPassword" class="a-label js-a-label" style="color: #D44511; font-size: 12px;">您还未设置密码&nbsp;<a (click)="clickForgotPassword()"  routerLink="/forget" target="_self" class="a-link" style="color: #D44511"><span class="underline">点击设置</span></a></label>
          <button type="submit" class="a-button is-primary mt-25 h-50" [ngClass]="(usernameVal && passwordVal && !usernameValid && !passwordValid) ? '' :'confirm-button'" id="login-button"><span>登录</span></button>
          <div class="link-holder text-right">
            <a (click)="clickForgotPassword()" href="/forget" target="_self" class="a-link forgot-password">忘记密码?</a>
          </div>
          <div class="thirdPartylogin hidden-xs hidden-sm" style="display: none;">
            <a class="a-link icoBg weixinLogin" href="javascript:;" (click)="wechatLogin()">微信登录</a>
          </div>
        </form>
      </div>
    </div>
    <!-- 注册弹出框需加 is-active类，同时去掉登录位置的这个类 -->
    <div id="registration-form" class="content" [ngClass]="{'is-active':showRegisterBox==true}">
      <div class="o-registration empty-registration">
        <form data-component="OForm" (submit)="register()" class="o-form">
          <div class="o-registration-form">
            <div class="m-error js-m-error is-hidden">
              <label class="a-label js-a-label has-error"></label>
            </div>
            <div class="e-mail-input">
             <!-- [ngClass]="{'is-filled has-error':mobileValid==true,'is-active':mobileActive==true }"-->
              <div data-component="MInput" class="m-input entry-item">
                <ng-container *ngIf="mobileValid; else mobileBlock;">
                  <label class="a-label js-a-label mb-5 tips">{{usernameError}}</label>
                </ng-container>
                <ng-template #mobileBlock>
                  <label class="a-label js-a-label mb-5" *ngIf="mobileVal && mobileVal.length > 0">手机号</label>
                </ng-template>
                <input autocomplete="off" maxlength="11" placeholder="手机号" class="p-0 border-none" type="tel" required name="mobile"  [(ngModel)]='mobileVal' (blur)="rBlurMobileCheck()"  (focus)="mobileActiveCheck()" title="手机号">
               <!-- <label *ngIf="mobileValid" class="a-label js-a-label" data-placeholder="" >{{usernameError}}</label>-->
              </div>
            </div>

            <div class="e-mail-input" *ngIf="hkUsers">
             <!-- [ngClass]="{'is-filled has-error':emailValid==true,'is-active':emailActive==true }"-->
              <div data-component="MInput" class="m-input entry-item bt-none" >
                <ng-container *ngIf="emailValid; else emailBlock;">
                  <label class="a-label js-a-label mb-5 tips">{{emailError}}</label>
                </ng-container>
                <ng-template #emailBlock>
                  <label class="a-label js-a-label mb-5" *ngIf="emailVal && emailVal.length > 0">邮箱</label>
                </ng-template>
                <input autocomplete="off" class="p-0 border-none" type="text" required name="email" placeholder="邮箱" [(ngModel)]='emailVal' (blur)="rBlurEmailCheck()"  (focus)="emailActiveCheck()" title="email">
               <!-- <label *ngIf="emailValid" class="a-label js-a-label" data-placeholder="" >{{emailError}}</label>-->
              </div>
            </div>

            <div class="e-mail-input pos_rel ">
              <!--[ngClass]="{'is-filled has-error':phoneCaptchaValid==true,'is-active':phoneCaptchaActive==true }"-->
              <div data-component="MInput" class="m-input entry-item bt-none flex flex-row">
                <div style="width: 100%; display: flex" [ngClass]="(phoneCaptchaValue && phoneCaptchaValue.length > 0) || phoneCaptchaValid ? 'flex-column' : '' ">
                  <ng-container *ngIf="phoneCaptchaValid; else phoneCaptchaBlock;">
                    <label class="a-label js-a-label mb-5 tips">{{phoneCaptchaError}}</label>
                  </ng-container>
                  <ng-template #phoneCaptchaBlock>
                    <label class="a-label js-a-label mb-5" *ngIf="phoneCaptchaValue && phoneCaptchaValue.length > 0">验证码</label>
                  </ng-template>
                  <input autocomplete="new-captcha" name="phoneCaptcha" class="p-0 border-none width_70" type="text" [(ngModel)]='phoneCaptchaValue' required (blur)="blurPhoneCaptchaCheck()" (focus)="phoneCaptchaActiveCheck()" placeholder="验证码" title="验证码">
                </div>
                <div style="width: 40%;display: flex;align-items: center;">
                  <button type="button" [disabled]="phoneCaptchaDisabled" (click)="openImageValid()" class="btn_code">{{sendPhoneLabel}}</button>
                </div>
               <!-- <label *ngIf="phoneCaptchaValid===true" class="a-label js-a-label" data-placeholder="">{{phoneCaptchaError}}</label>-->
              </div>
              <div class="codeBox" [ngClass]="{'is-hidden':hiddenImageValidBox}">
                <h6>验证图形码</h6>
                <div class="iptText">
                  <input autocomplete="off" type="text" name ='imageValidValue' [(ngModel)]='imageValidValue' title="图形验证码">
                  <img [id]="imageValidSrc" [src]="imageValidSrc" alt="图形验证码">
                  <p class="font_s_regular"><a style="text-decoration: none;" href="javascript:void(0)" (click)="createImage()">换一张</a></p>
                </div>
                <label *ngIf="imageValidError" class="a-label js-a-label codeError">{{imageValidErrorLabel}}</label>
                <button type="button" (click)="imageValueCheck()" class="a-button is-primary">确定</button>
                <button type="button" (click)="closeCodeBox()" class="a-button is-primary">取消</button>
              </div>
            </div>
            <!--[ngClass]="{'is-filled has-error':rPasswordValid==true,'is-active':rPasswordActive==true }"-->
            <div data-component="MInput" class="m-input password entry-item bt-none"  id="password">
             <!-- <label class="a-label js-a-label">密码</label>-->
              <ng-container *ngIf="rPasswordValid; else paBlock;">
                <label class="a-label js-a-label mb-5 tips">{{passwordError}}</label>
              </ng-container>
              <ng-template #paBlock>
                <label class="a-label js-a-label mb-5" *ngIf="rPasswordVal && rPasswordVal.length > 0">密码</label>
              </ng-template>
              <input autocomplete="new-password" placeholder="密码" class="p-0 border-none" type="text" onfocus="this.type='password'" required name="r_username" [(ngModel)]='rPasswordVal' (blur)="rBlurPasswordCheck()"  (focus)="rPasswordActiveCheck()" title="密码">
             <!-- <label *ngIf="rPasswordValid" class="a-label js-a-label" data-placeholder="">{{passwordError}}</label>-->
            </div>
           <!-- [ngClass]="{'is-filled has-error':rePasswordValid==true,'is-active':rePasswordActive==true }"-->
            <div data-component="MInput" class="m-input password-repeat entry-item bt-none" >
             <!-- <label class="a-label js-a-label">确认密码</label>-->
              <ng-container *ngIf="rePasswordValid; else rpaBlock;">
                <label class="a-label js-a-label mb-5 tips">{{rePasswordError}}</label>
              </ng-container>
              <ng-template #rpaBlock>
                <label class="a-label js-a-label mb-5" *ngIf="rePasswordVal && rePasswordVal.length > 0">确认密码</label>
              </ng-template>
              <input autocomplete="new-password" placeholder="确认密码" class="p-0 border-none" name="rePassword" type="text" onfocus="this.type='password'" required  [(ngModel)]='rePasswordVal' (blur)="reBlurPasswordCheck()" title="确认密码" (focus)="rePasswordActiveCheck()">
              <!--<label *ngIf="rePasswordValid" class="a-label js-a-label" data-placeholder="">{{rePasswordError}}</label>-->
            </div>
            <label *ngIf="registerError" class="a-label js-a-label"  style="color: #D44511;font-size: 12px;margin-top: 10px;">{{registerErrorLabel}}</label>
            <button type="submit" class="a-button is-primary register-btn h-50" [ngClass]="checkInfoConfirm() ? '' :'confirm-button'" id="signed-button"><span>提交</span></button>
            <div class="checkbox-input">
              <div class="m-checkbox u-clearfix" [ngClass]="{'has-error':policiesUnChecked}" id="newsletter">
                <input style="display: none;" name="police" [(ngModel)]='policeVal' type="checkbox" required class="a-checkbox" id="connected_newsletter">
                <label style="margin-top: 2px;" class="a-label js-a-label custom-box" for="connected_newsletter"></label>
                <label class="a-label js-a-label" for="connected_newsletter">
                  <!-- <a href="/privacy-policy" target="_blank" [routerLink]="['/privacy-policy']" class="a-link open-lightbox policiesLink"> </a>-->
                  <span class="newsLetterText" [ngClass]="{'consent_clause':policeVal}">我同意并接受《<a href="/privacy-policy" class="underLine">个人信息保护政策</a>》和《<a href="/terms-and-conditions" class="underLine">在线购物条款和条件</a>》。</span>
                </label>
              </div>
            </div>
            <div class="checkbox-input">
              <div class="m-checkbox u-clearfix" [ngClass]="{'has-error':policiesUnChecked1}" id="newsletter1">
                <input style="display: none;" name="police" [(ngModel)]='policeVal1' type="checkbox" required class="a-checkbox" id="connected_newsletter1">
                <label style="margin-top: 2px;" class="a-label js-a-label custom-box" for="connected_newsletter1"></label>
                <label class="a-label js-a-label" for="connected_newsletter1">
                  <!-- <a href="/privacy-policy"  target="_blank"  [routerLink]="['/privacy-policy']" class="a-link open-lightbox policiesLink"></a>  -->
                  <span class="newsLetterText" [ngClass]="{'consent_clause':policeVal1}">我同意如《<a href="/privacy-policy" class="underLine">个人信息保护政策</a>》所述的，遵循相关国家规定的对我个人信息的跨境处理。</span>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--小程序用户设置密码，出框需加 is-active类，同时去掉登录,注册位置的这个类 -->
  </div>
</div>

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  OnInit,
  Injectable,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import {InitializeService} from '../../../services/initialize.service';
import {RequestService} from '../../../services/request.service';
import {RemoteServer} from '../../../config/config.remoteServer';
import {CartService} from '../../../services/cart.service';
import {CanActivate, Router} from '@angular/router';
import {CustomerService} from '../../../services/customer.service';
import {StorageService} from '../../../services/storage.service';
import {Observable} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
const apiConfig = require('../../../config/config.api.json');
import {FavoriteService} from '../../../services/favorite.service';
import {UdeskService} from "../../../services/udesk.service";
import {TrackingcodeService} from '../../../services/trackingcode.service';
import {CryptoService} from "../../../services/crypto.service";
import {CallComponentService} from '../../../services/callcomponent.service';
// import {Angulartics2} from 'angulartics2';


@Component({
  selector: 'sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit, OnDestroy {
  @Input() showLoginBox:any; // 显示登录框
  @Input() loginValidArr:any; // 显示登录错误
  @Input() RegisterValidArr:any; // 显示注册错误
  @Input() closeLoginRegister: any; // 关闭注册
  @Output() public close = new EventEmitter();
  @Output() public closeBindMobileBox = new EventEmitter();
  @Output() public headerLink = new EventEmitter();
  @Input() showRegisterBox:any; // 显示注册框
  usernameActive = false; // 聚焦用户名框
  passwordActive = false; // 聚焦密码框
  mobileActive = false; // 聚焦手机号框
  emailActive = false;
  rPasswordActive = false; // 聚焦密码框
  rePasswordActive = false; // 聚焦重置密码框
  phoneCaptchaActive = false; // 聚焦验证码框
  policiesUnChecked = false; // 隐私协议
  policiesUnChecked1 = false; // 隐私协议
  hiddenImageValidBox = true; // 图片验证框显示(注册)
  imageValidId: any; // 图片验证码id
  imageValidSrc: any; // 图片验证码地址
  imageValidValue: any; // 图像验证码输入值
  imageValidError = false; // 图形验证码错误
  imageValidErrorLabel: any; // 图形验证码提示
  isText: boolean = false;
  notSetPassword:boolean = false;
//  loginLoading:boolean = false;//登录中...
//  loginLabel:string='登录';//登录

  usernameVal: any; // 用户名
  passwordVal: any; // 密码
  mobileVal: any; // 手机号（注册）
  emailVal: any; // 邮箱（注册）
  rPasswordVal: any; // 密码（注册）
  rePasswordVal: any; // 确认密码（注册）
  usernameValid:any = false; // 用户名验证

  usernameError: any; // 用户名验证错误提示
  emailError: any;
  passwordError: any; // 密码验证错误提示
  rePasswordError: any; // 重复密码错误提示（注册）
  phoneCaptchaError: any; // 手机验证错误提示（注册）
  mobileValid:any = false; // 手机号验证
  emailValid:any = false;
  hkUsers: boolean = false;//是否为香港手机注册
  passwordValid:any = false; // 密码验证
  rPasswordValid:any = false; // 密码验证(注册)
  rePasswordValid:any = false; // 确认密码验证(注册)
  phoneCaptchaValid:any = false; // 验证码验证(注册) true 显示错误
  phoneCaptchaDisabled:any = false; // 发送验证码禁用
  loginError:any = false; // 登录错误
  phoneCaptchaValue: any;
  sendPhoneLabel:any = '发送验证码';
  registerErrorLabel: any; // 注册失败message
  registerError:any = false; // 注册错误
  policeVal:any = false;
  policeVal1:any = false; // 隐私协议勾选
  timerEffect:any;
  public timer:any = null;
  public eyesOpen: Boolean = false;
  public showBindMobile:boolean = false;
  public captchaKey:any;
  public memberWxInfo: any = {wxUnionId:'',nickName:'',memberLevelId:'',isActive:'',customerGroupId:''};
  public customerApiResponse:any = {code:'',msg:'',data:{customerId:'',mobile:'',groupName:'',memberId:'',email:'',dob:''}};
  public customerData:any = {customerId:'',mobile:'',groupName:'',memberId:'',email:'',birthday:'',staffCard:'',groupId:''};
  public loginLogRs:any = {code:'',message:'',data:''};
  constructor(public init: InitializeService,
              private request: RequestService,
              private cart: CartService,
              private router: Router,
              private customer:CustomerService,
              private HOST:RemoteServer,
			        private crypto : CryptoService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private favoriteService:FavoriteService,
              private udeskService: UdeskService,
			  private tracking: TrackingcodeService,
              private localStorage: StorageService,
			  public callComponentService: CallComponentService,) { }

  ngOnInit() {

    if (this.init.isLogin()) {
      // this.router.navigate(['customer/my-account/index']);
    }
    if (!this.init.isLogin()) {

      if(this.localStorage.getItem('wxUserSetPassword')){
        this.showLoginBox = false;
      }

      if(this.localStorage.getItem('showWxUserRegisterBox')){
        this.showRegister();
        this.showBindMobile = true;
      }
    }
  }

// 登录注册
  // 手机号校验
  blurMobileCheck() {
    if (this.usernameVal) {
      //const myreg = /^(1[3|4|5|6|7|8|9])\d{9}$/;
      const myreg = /^((1[3|4|5|6|7|8|9])\d{9})|(([6|9])\d{7})$/;
      const valid = myreg.test(this.usernameVal);
      this.usernameValid = valid ? null : true;
      this.usernameError = valid ? '' : '手机号格式不正确';
    } else {
      this.usernameValid = true;
      this.usernameError = '请输入手机号';
    }
    this.usernameActive = false;
    if (this.usernameValid) {
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(function () {
          that.usernameValid = false;
        }, 3000);
        return false;
      }
    }
    return true;
  }

  usernameActiveCheck() {
    this.usernameValid = null;
    this.usernameActive = true;
  }
  passwordActiveCheck() {
    this.passwordValid = null;
    this.passwordActive = true;
  }
  mobileActiveCheck() {
    this.mobileValid = null;
    this.mobileActive = true;
  }

  emailActiveCheck() {
    this.emailValid = null;
    this.emailActive = true;
  }
  rPasswordActiveCheck() {
    this.rPasswordValid = null;
    this.rPasswordActive = true;
  }
  rePasswordActiveCheck() {
    this.rePasswordValid = null;
    this.rePasswordActive = true;
  }
  phoneCaptchaActiveCheck() {
    this.phoneCaptchaValid = null;
    this.phoneCaptchaActive = true;
  }
  // 密码校验,兼容老版本6位密码
  blurPasswordCheck() {
    if (this.passwordVal) {
      const myreg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%\^&*?]{8,16}$/;
      const valid = myreg.test(this.passwordVal);
      this.passwordValid = valid ? null : true;
      this.passwordError = valid ? '' : '密码必须为8～16个英文字母加数字或符号的组合';
    } else {
      this.passwordValid = true;
      this.passwordError = '请输入密码';
    }
    this.passwordActive = false;
    if (this.passwordValid) {
      if (isPlatformBrowser(this.platformId)) {
        const that = this;
        setTimeout(function () {
          that.passwordValid = false;
        }, 3000);
        return false;
      }
    }
    return true;
  }
  /*密码查看*/
  passWordShow() {
    this.eyesOpen = !this.eyesOpen;
    this.isText = !this.isText;
  }
  showRegister() {
    this.showLoginBox = false;
    this.showBindMobile = false;
    this.showRegisterBox = true;
    this.eyesOpen = false;
    this.isText = false;
    this.createImage();
  }

  showLogin() {
    this.showLoginBox = true;
    this.showBindMobile = false;
    this.showRegisterBox = false;

  }
  // 注册校验
  rBlurMobileCheck() {
    if (this.mobileVal) {
      let valid = false;
      const hkreg = /^(([6|9])\d{7})$/;
      const myreg = /^(1[3|4|5|6|7|8|9])\d{9}$/;
      if(hkreg.test(this.mobileVal)){
        valid = hkreg.test(this.mobileVal);
        this.hkUsers = true;
      }else{
        this.hkUsers = false;
        valid = myreg.test(this.mobileVal);
      }
      this.mobileValid = valid ? null : true;
      this.usernameError = valid ? '' : '手机号格式不正确';
    } else {
      this.mobileValid = true;
      this.usernameError = '请输入手机号';
    }
    this.mobileActive = false;
    if (this.mobileValid) {
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
       this.timer = setTimeout(function () {
          that.mobileValid = false;
        }, 3000);
        return false;
      }
    }
    return true;
  }

  rBlurEmailCheck(){
    if(this.hkUsers){
      if (this.emailVal) {
        const myreg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const valid = myreg.test(this.emailVal);
        this.emailValid = valid ? null : true;
        this.emailError = valid ? '' : '邮箱格式不正确';
      } else {
        this.emailValid = true;
        this.emailError = '请输入邮箱地址';
      }
      if (this.emailValid) {
        return false;
      }
    }
    return true;
  }

  // 密码校验
  rBlurPasswordCheck() {
    if (this.rPasswordVal) {
      const myreg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%\^&*?]{8,16}$/;
      const valid = myreg.test(this.rPasswordVal);
      this.rPasswordValid = valid ? null : true;
      this.passwordError = valid ? '' : '密码必须为8～16个英文字母加数字或符号的组合';
    } else {
      this.rPasswordValid = true;
      this.passwordError = '请输入密码';
    }
    this.rPasswordActive = false;
    if (this.rPasswordValid) {
      if (isPlatformBrowser(this.platformId)) {
        const that = this;
        this.timer = setTimeout(function () {
          that.rPasswordValid = false;
        }, 3000);
        return false;
      }
    }
    return true;
  }
  reBlurPasswordCheck() {
    if (this.rePasswordVal && this.rePasswordVal !== this.rPasswordVal) {
      this.rePasswordError = '两次输入的密码不一致';
      this.rePasswordValid = true;
    } else if (!this.rePasswordVal) {
      this.rePasswordValid = true;
      this.rePasswordError = '请确认您的密码';
    } else {
      this.rePasswordValid = false;
    }
    this.rePasswordActive = false;
    if (this.rePasswordValid) {
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(function () {
          that.rePasswordValid = false;
        }, 3000);
      }
      return false;
    }
    return true;
  }
  blurPhoneCaptchaCheck() {
    this.phoneCaptchaActive = false;
  }

  checkVerificationCode() {
    if (!this.phoneCaptchaValue) {
      this.phoneCaptchaValid = true;
      this.phoneCaptchaError = '验证码必填';
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(() => {
          that.phoneCaptchaValid = false;
        }, 3000);
      }
      return false;
    } else {
      this.phoneCaptchaValid = false;
    }
    let codeResult: any = {result: '', msg: '',data:''};
    const params = {mobile:this.mobileVal,verifyCode:this.phoneCaptchaValue};

    this.customer.validateVerifyCode(JSON.stringify(params)).then( val => {
        codeResult = val;
        if(codeResult.code === 1000){
          this.phoneCaptchaValid = false;
        }else{
          this.phoneCaptchaValid = true;
          this.phoneCaptchaError = '验证码错误';
          const that = this;
          if (isPlatformBrowser(this.platformId)) {
            this.timer = setTimeout(() => {
              that.phoneCaptchaValid = false;
            }, 3000);
          }
          this.createImage();
          return false;
        }
		return;
    }).catch(error => error);
    return true;
  }

  /*发送手机验证码*/
  sendPhoneVerification() {
    if (!this.rBlurMobileCheck()) {
      return false;
    }
    this.phoneCaptchaDisabled = true;
    const that = this;
    let sendResult: any = {code: '', msg: '',result:''};
    let smsSign = this.init.aliyunSmsSign;
    let smsTemplateId = this.init.registerSmsTplId;
    let platform = "aliyun";
    let tempMobile = this.mobileVal;
    const hkmyreg = /^(([6|9])\d{7})$/;
    if (hkmyreg.test(tempMobile)) {
      smsSign = this.init.aliyunSmsHkSign;
      smsTemplateId = this.init.registerSmsHkTplId;
      tempMobile = "852"+tempMobile;
    }

    const params = {merchantId:this.init.merchantId,mobile:tempMobile,signName:smsSign,templateId:smsTemplateId,templateParams:{},platform:platform};
    this.customer.sendVerifyCode(JSON.stringify(params)).then( val => {
      sendResult = val;
      if(sendResult.code === 1000){
        this.localStorage.setItem('is_send_register_code', new Date().valueOf());
        let int = 59;
        const timerEffect =  setInterval(function () {
          that.sendPhoneLabel = '重新发送(' + int-- + ')';
          if (int <= 1) {
            that.sendPhoneLabel = '发送验证码';
            that.phoneCaptchaDisabled = false;
            clearInterval(timerEffect);
          }
        }, 1000);
        this.timerEffect = timerEffect;
      }else{
        this.phoneCaptchaValid = true;
        this.phoneCaptchaDisabled = false;
        if(sendResult.msg === '触发小时级流控Permits:5'){
          this.phoneCaptchaError = '验证码发送过于频繁，请稍后再试';
        }else{
          this.phoneCaptchaError = sendResult.msg;
        }
        if (isPlatformBrowser(this.platformId)) {
          this.timer = setTimeout(() => {
            that.phoneCaptchaValid = false;
          }, 3000);
        }
      }
    }).catch(error => error);
	return;
  }

  login() {
      if (this.blurMobileCheck()) {
        let codeResult: any = {code: '', data: {mobile:'',jwt:'',memberId:'',nickName:''}, message: ''};

		let keyStr = this.init.aesKey;
		let ivStr = this.init.aesIv;
		let aesPassword = this.crypto.encrypt(this.passwordVal, keyStr, ivStr );
        const params = {'loginName':this.usernameVal,'password':aesPassword,'merchantId':this.init.merchantId};
        this.customer.login(params).then(val => {
          codeResult = val;

          if (codeResult.code === 1000) {
            this.close.emit(false);
            this.localStorage.setItem('jwt', codeResult.data.jwt);
            this.localStorage.setItem('uuid', codeResult.data.jwt);
            this.localStorage.setItem('loginTime', new Date().valueOf());

            this.localStorage.setItem('cloud_jwt', codeResult.data.jwt);
            this.localStorage.setItem('cloud_customer_id', codeResult.data.customerId);

			if (this.init.gaTrackingFlag) {
				// 向Google推送事件
				this.tracking.gaTrackingLoginRegister('login_completed');
			}

			//查询用户是否同意个人隐私政策
			this.customer.agreementState().then(val => {
				if(val.code === 1000){
					this.init.agreeAgreement = val.data.isAgreed;
				}
			});

            // 校验是否有cartkey
            if (this.localStorage.getItem('quote_id') && this.localStorage.getItem('quote_id') != undefined && this.localStorage.getItem('quote_id') != null) { // 合并购物车
              this.cart.mergeCart().then(_quoteIdRes => {
                this.cart.getCartData().then( _res => {
                  this.callComponentService.loginAndGetCart.emit(_res);

                }).catch(error => error);
              });
            } else {
              this.cart.getQuoteId(this.localStorage.getItem('quote_id')).then(_quoteIdRes => {
                this.cart.getCartData().then( _res => {
                  this.callComponentService.loginAndGetCart.emit(_res);
                }).catch(error => error);
              });
            }

            //查询收藏信息
            this.favoriteItems();
            //查询用户组
            this.getCustomerData();
			this.callComponentService.loginRegisterBox.next(false);
            //记录已入会用户信息
            this.customer.getCrmMemberInfo().then(value => {
              this.memberWxInfo = value.data;
                this.localStorage.setItem('wxUnionId',this.memberWxInfo.wxUnionId);
                this.localStorage.setItem('wxNickName',this.memberWxInfo.nickName);
                this.localStorage.setItem('memberLevelId',this.memberWxInfo.memberLevelId);
                this.localStorage.setItem('memberIsActive',this.memberWxInfo.isActive);

				this.init.memberLevelId = this.memberWxInfo.memberLevelId;

                let memberLevelId = this.memberWxInfo.memberLevelId;
                let isActive = this.memberWxInfo.isActive;
                let level = 'normal';
                if(isActive == "Active" && (memberLevelId == 2 || memberLevelId == 3)){
                  level = 'vip'
                }
                let mobile = this.localStorage.getItem('mobile');
                this.udeskService.setCustomerInfo(mobile,level);
            }).catch(error => error);

            if (codeResult.data.hasOrder) {
              this.localStorage.setItem('has_order', 1);
              this.init.defaultAccountPage = '/my-account/order';
            } else {
              this.localStorage.removeItem('has_order');
              this.init.defaultAccountPage = '/my-account/profile';
            }
            this.init.setLoginSession(codeResult.data.customerId, codeResult.data.mobile, codeResult.data.mobile);
			//记录登录日志
            this.insertLoginLog('CUSTOMER_LOGIN');

            if (this.router.url === '/reset' || this.router.url === '/forget') {
              let referer_url = '';
              if (codeResult.data.hasOrder) {
                referer_url = '/my-account/order';
              } else {
                referer_url = '/my-account/profile';
              }
              const param = this.init.referer_url_Param;
              this.init.referer_url = '';
              this.init.referer_url_Param = '';
              this.router.navigate([referer_url], {queryParams: param});
              return;
            }
            if (isPlatformBrowser(this.platformId)) {
              this.timer = setTimeout(() => {
                this.initSignInBox();
              }, 1000);
            }
          } else {
            if(codeResult.code == '405'){
              this.localStorage.setItem('noSetPassword','yes');
              this.notSetPassword = true;
            }else{
              this.loginError = true;
              if (isPlatformBrowser(this.platformId)) {
                const that = this;
                this.timer = setTimeout(function () {
                  that.loginError = false;
                }, 3000);
              }
            }
          }
        }).catch(error => error);
      }
  }

  favoriteItems() {
    this.favoriteService.favoritesList(this.favoriteService.favoritePageSize,this.favoriteService.favoriteCurrentPage).then(_val=> {
    });
  }

	insertLoginLog(logType:any){
		this.customer.clientIpService().then(val=>{
		  this.loginLogRs = val;
		  if(this.loginLogRs.code === 1000){
			const params = {'customerId':this.init.userId,'ip':this.loginLogRs.data,'merchantId':this.init.merchantId,'logType':logType};
			this.customer.customerLoginLog(params).then( _val => {
			})
		  }
		})
	  }

  //查询用户组
  getCustomerData(){
    this.customer.customerData().then( customerVal => {
      this.customerApiResponse = customerVal;
      if(this.customerApiResponse.code == 1000) {
        this.customerData = this.customerApiResponse.data;
        if(this.customerData.groupName == '员工组'){
          const params = {'refresh': true};
          this.customer.refreshCustomerGroup(params)
        }
        this.localStorage.setItem('groupId', this.customerData.groupId);
      }
    });
  }
  policiesCheck() {
    if (!this.policeVal) {
      this.policiesUnChecked = true;
      const that = this;
      this.timer = setTimeout(function () {
        that.policiesUnChecked = false;
      }, 3000);
      return false;
    } else {
      this.policiesUnChecked = false;
    }
    const the = this;
    if (!this.policeVal1) {
      this.policiesUnChecked1 = true;
      this.timer = setTimeout(function () {
        the.policiesUnChecked1 = false;
      }, 3000);
      return false;
    } else {
      the.policiesUnChecked1 = false;
    }
    return true;
  }

  register() {
   if (this.rBlurMobileCheck() && this.rBlurEmailCheck() && this.checkVerificationCode() && this.rBlurPasswordCheck() && this.reBlurPasswordCheck() && this.policiesCheck()) {
        let create: any = {code: '', msg: '',data:{customer_id:'',mobile:'',passcode:'',group_id:''}};
        const mobile = this.mobileVal;
        const email = this.emailVal ? this.emailVal : '';
        const verification_code = this.phoneCaptchaValue;
        const password = this.rPasswordVal;
        const confirmation_password = this.rePasswordVal;

        //微信登录用户绑定手机号
        if(this.localStorage.getItem('tempOpenId') && this.localStorage.getItem('tempAppId')){
          const params = {appId:this.localStorage.getItem('tempAppId'),
          openId:this.localStorage.getItem('tempOpenId'),
          confirmationPassword:confirmation_password,
          merchantId:this.init.merchantId,
          mobile:mobile,
          nickName:mobile,
          password:password,
          verifyCode:verification_code,
          storeId:this.init.storeId};
          this.customer.wxBindMobile(JSON.stringify(params)).then( val => {
            this.localStorage.removeItem('tempOpenId');
            this.localStorage.removeItem('tempAppId');
            this.localStorage.removeItem('showWxUserRegisterBox');
            this.localStorage.removeItem('wxUserSetPassword');
            create = val;
            if(create.code === 1000){
              this.showBindMobile = false;
              this.showRegisterBox = false;
              this.showLoginBox = false;
              this.closeBindMobileBox.emit(true);
              this.close.emit(true);
              //绑定成功自动登录
              this.localStorage.setItem('jwt', create.data.jwt);
              this.localStorage.setItem('cloud_jwt', create.data.jwt);
              this.localStorage.setItem('cloud_customer_id', create.data.customerId);
              this.localStorage.setItem('loginTime', new Date().valueOf());
              this.init.setLoginSession(create.data.customerId, create.data.mobile, create.data.mobile);
			        //记录登录日志
              this.insertLoginLog('CUSTOMER_LOGIN');

              //获取用户信息
              this.getCustomerData();
              if (this.init.gaTrackingFlag) {
                // 向Google推送浏览事件
                this.tracking.gaTrackingLoginRegister('registration_completed');
              }
            }else{
              this.registerError = true;
              this.registerErrorLabel = create.msg;
              const _that = this;
              this.timer = setTimeout(function () {
                _that.registerError = false;
              }, 3000);
            }
          }).catch(error => error);
        } else{
          //普通注册用户
          const params = {confirmationPassword:confirmation_password,merchantId:this.init.merchantId,mobile:mobile,email:email,nickName:mobile,password:password,verifyCode:verification_code,smsPlatform:this.init.smsPlatform};
          this.customer.register(JSON.stringify(params)).then( val => {
            create = val;
            if(create.code === 1000){
              if (this.init.gaTrackingFlag) {
                 // 向Google推送浏览事件
                this.tracking.gaTrackingLoginRegister('registration_completed');
              }
              this.timer = setTimeout(() => {
                this.initSignInBox();
              }, 1000);
            }else{
              this.registerError = true;
              this.registerErrorLabel = create.msg;
              const _that = this;
              this.timer = setTimeout(function () {
                _that.registerError = false;
              }, 3000);
            }
          }).catch(error => error);
        }
      }
  }

  loginPost() {
    let codeResult: any = {code: '', data: {mobile:'',jwt:'',memberId:'',nickName:''}, message: ''};
    const params = {'loginName':this.usernameVal,'password':this.passwordVal,'merchantId':this.init.merchantId};
    this.customer.login(params).then(val => {
          codeResult = val;
          if (codeResult.code === '1000') {
            this.close.emit(false);
            this.localStorage.setItem('jwt', codeResult.data.jwt);
            this.localStorage.setItem('loginTime', new Date().valueOf());
            if (this.init.gaTrackingFlag) {
              // 向Google推送事件
              this.tracking.gaTrackingLoginRegister('login_completed');
            }

            this.init.setLoginSession(codeResult.data.customerId, codeResult.data.mobile, codeResult.data.mobile);
            this.cart.getCartData(this.localStorage.getItem('quote_id')).then( _res => {
              this.localStorage.removeItem('quote_id');
            }).catch(error => error);
            this.router.navigate(['/']);
          }
        }
      );
  }

  clickForgotPassword() {
    this.close.emit(false);
    this.headerLink.emit(false);
  }
  openImageValid() {
    this.createImage();
    this.phoneCaptchaValue = '';
    this.imageValidValue = '';
    if (!this.rBlurMobileCheck()) {
      return false;
    }
    this.hiddenImageValidBox = false;
	  return;
  }

  createImage() {
    let host = this.HOST.BASIC_HOST;
    let captcha_key_result: any = {code: '', data: {'captchaKey': ''}, msg: ''};
    this.customer.imageCaptchaKey().then( val => {
      captcha_key_result = val;
      if (captcha_key_result.code === 1000) {
        this.captchaKey = captcha_key_result.data.captchaKey;
        this.imageValidSrc = host+this.customer.imageCaptcha(captcha_key_result.data.captchaKey);
      }
    }).catch(error => error);
  }

  imageValueCheck() {
    if (this.imageValidValue) {
      let result: any = {code: '', msg: '',data:{}};
      const params = {'captchaKey':this.captchaKey,'captchaValue':this.imageValidValue};
      this.customer.checkImageCaptcha(JSON.stringify(params)).then( val => {
        result = val;
        if(result.code === 1000){
          this.closeCodeBox();
          this.sendPhoneVerification();
        }else{
          this.imageValidError = true;
          this.imageValidErrorLabel = '验证码错误';
          const that = this;
          if (isPlatformBrowser(this.platformId)) {
            this.timer = setTimeout(function () {
              that.imageValidError = false;
            }, 3000);
          }
        }
      });
    } else {
      this.imageValidError = true;
      this.imageValidErrorLabel = '请输入验证码';
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(function () {
          that.imageValidError = false;
        }, 3000);
      }
    }
  }

  closeCodeBox() {
    //this.createImage();
    this.imageValidError = false;
    this.hiddenImageValidBox = true;
  }

  initSignInBox() {
    this.showLoginBox = true;
    this.showRegisterBox = false;
    this.showBindMobile = false;
    this.usernameVal = undefined;
    this.passwordVal = undefined;
    this.eyesOpen = false;
    this.mobileVal = undefined;
    this.rPasswordVal = undefined;
    this.rePasswordVal = undefined;
    this.imageValidValue = undefined;
    this.phoneCaptchaValue = undefined;
    this.usernameValid = false;
    this.passwordValid = false;
    this.mobileValid = false;
    this.emailValid = false;
    this.policiesUnChecked = false;
    this.phoneCaptchaDisabled = false;
    this.sendPhoneLabel = '发送验证码';
    this.imageValidError = false;
    this.phoneCaptchaValid = false;
    this.loginError = false;
    this.registerError = false;
    this.policeVal = false;
    clearInterval(this.timerEffect);
  }

  /**
   * 微信网页授权登录
   */
  wechatLogin() {
    var timestamp = new Date().getTime();
    const wxLoginUrl = 'customer/weixin/'+this.init.wxLoginAppid+'/login_qrcode_url'+'?timestamp='+timestamp;
    let result: any = {code: '', data: {'redirect_url': ''}, message: ''};
    this.request.get(apiConfig.wxLoginApi + wxLoginUrl)
      .subscribe(
        val => {
          result = val;
          if (result.code === 1000) {
            if (isPlatformBrowser(this.platformId)) {
              window.location.href = result.data['redirectUrl'];
            }
          }
        }
      );
  }

  checkInfoConfirm(){
    if((this.mobileVal && this.mobileVal.length > 0 && !this.mobileValid) &&
      ((this.hkUsers && this.emailVal && this.emailVal.length > 0 && !this.emailValid) || !this.hkUsers) &&
      (this.phoneCaptchaValue && this.phoneCaptchaValue.length > 0 && !this.phoneCaptchaValid) &&
      (this.rPasswordVal && this.rPasswordVal.length > 0 && !this.rPasswordValid) &&
      (this.rePasswordVal && this.rePasswordVal.length > 0 && !this.rePasswordValid) &&
      this.policeVal && this.policeVal1
    ){
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.timer = null;
  }
}
@Injectable()
export class CanNotGoToNext implements CanActivate {

  constructor(private router: Router, public init: InitializeService) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return new Observable((observer) => {
      // 拥有 `admin` 角色
      if (this.init.userId) {
        observer.next(false);
        observer.complete();
        this.router.navigate(['/my-account/profile']);
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

}
